import React from 'react';
import { useCmpSettings } from '../hooks/useCmpSettings';

/**
 * A High Order Component (HOC) that blocks rendering a component passed
 * in props until the cmpSettings from consentService can be obtained.
 *
 * @see WithCmpSettingsNonBlocking for a non blocking version of this HOC
 * *
 * @param {Component} Component - A React component
 * @returns Component - A newly create React component over the one passed in props
 */
export const WithCmpSettingsBlocking = Component => {
  return props => {
    const cmpSettings = useCmpSettings();
    return cmpSettings ? (
      <Component cmpSettings={cmpSettings} {...props} />
    ) : null;
  };
};
